var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let BureauetCookiebotDeclaration = class BureauetCookiebotDeclaration extends Vue {
    mounted() {
        let cookiebotScript = document.createElement('script');
        let _id = this.id ? this.id : "5a393fe9-6cdf-4f2e-8449-22ef7940ddfd";
        cookiebotScript.setAttribute('id', 'CookieDeclaration');
        cookiebotScript.setAttribute('src', `https://consent.cookiebot.com/${_id}/cd.js`);
        let elm = document.getElementById("cookieBotDeclarationWrapper");
        if (elm) {
            elm.appendChild(cookiebotScript);
        }
    }
};
BureauetCookiebotDeclaration = __decorate([
    Component({
        props: {
            id: String
        }
    })
], BureauetCookiebotDeclaration);
export default BureauetCookiebotDeclaration;
