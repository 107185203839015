var render, staticRenderFns
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\_Web\\Business Institute\\Git\\BusinessInstitute\\App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('617ab0be')) {
      api.createRecord('617ab0be', component.options)
    } else {
      api.reload('617ab0be', component.options)
    }
    
  }
}
component.options.__file = "src/components/App.vue"
export default component.exports