import { render, staticRenderFns } from "./ContentRendererItem.vue?vue&type=template&id=f0caafe2&"
import script from "./ContentRendererItem.vue?vue&type=script&lang=ts&"
export * from "./ContentRendererItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\_Web\\Business Institute\\Git\\BusinessInstitute\\App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0caafe2')) {
      api.createRecord('f0caafe2', component.options)
    } else {
      api.reload('f0caafe2', component.options)
    }
    module.hot.accept("./ContentRendererItem.vue?vue&type=template&id=f0caafe2&", function () {
      api.rerender('f0caafe2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ContentRendererItem.vue"
export default component.exports