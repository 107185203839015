var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let ContentRendererItem = class ContentRendererItem extends Vue {
    buttonName(doctype) {
        if (doctype === "podcast") {
            return "Hør podcast";
        }
        else if (doctype === "report") {
            return "Læs rapport";
        }
        else if (doctype === "article") {
            return "Læs artikel";
        }
        else if (doctype === "bookSkim") {
            return "Læs book skim";
        }
        else if (doctype === "interview") {
            return "Læs interview";
        }
        else if (doctype === "event") {
            return "Læs mere om eventet";
        }
        else if (doctype === "biEmployee") {
            return "Læs mere om personen";
        }
        else if (doctype === "articleReview") {
            return "Læs article review";
        }
        else {
            return "Læs mere";
        }
    }
    contentType(doctype) {
        if (doctype === "podcast") {
            return "Podcast";
        }
        else if (doctype === "report") {
            return "Rapport";
        }
        else if (doctype === "article") {
            return "Artikel";
        }
        else if (doctype === "bookSkim") {
            return "Book Skim";
        }
        else if (doctype === "interview") {
            return "";
        }
        else if (doctype === "event") {
            return "Event";
        }
        else if (doctype === "biEmployee") {
            return "";
        }
        else if (doctype === "articleReview") {
            return "Article Review";
        }
        else {
            return "Insights";
        }
    }
};
ContentRendererItem = __decorate([
    Component({
        props: {
            article: Object
        }
    })
], ContentRendererItem);
export default ContentRendererItem;
