import Vue from 'vue';
import * as moment from 'moment';
Vue.filter('formatDate', (value) => {
    return moment(value).format('MM-DD-YYYY');
});
Vue.filter('formatDateAndHour', (value) => {
    return moment(value).format('MM-DD-YYYY HH:mm');
});
Vue.filter('formatDateWithWeekday', (value) => {
    return moment(value).format('dddd D. MMMM');
});
Vue.filter('weekdayShorthand', (value) => {
    return moment(value).format('ddd');
});
Vue.filter('formatDateAndMonth', (value) => {
    return moment(value).format('MM/DD');
});
