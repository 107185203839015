import { Service } from "@jcd-web/basic-service";
import { UmbracoContentApi } from "../api/UmbracoContentApi";
export class UmbracoContentService extends Service {
    static get(id, select, propertyLevel) {
        return UmbracoContentApi.get(id, select != null ? select.join(",") : "", propertyLevel).then(function (res) {
            return res.data;
        });
    }
    static getChildren(id, documentTypeAlias, select, propertyLevel, skip, take) {
        return UmbracoContentApi.getChildren(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
    static getDescendants(id, documentTypeAlias, select, propertyLevel, skip, take) {
        return UmbracoContentApi.getDescendants(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
    static getAncestor(id, documentTypeAlias, select, propertyLevel) {
        return UmbracoContentApi.getAncestor(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel).then(function (res) {
            return res.data;
        });
    }
    static search(query, select, propertyLevel, skip, take) {
        return UmbracoContentApi.search(query, select, propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
    static urls(url, select, propertyLevel) {
        return UmbracoContentApi.urls(url, select, propertyLevel).then(function (res) {
            return res.data;
        });
    }
}
