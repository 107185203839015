import { Api } from "@jcd-web/basic-api";
export class UmbracoContentApi extends Api {
    static get(id, select, propertyLevel) {
        let params = {};
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.get(id.toString(), { params: params });
    }
    static getChildren(id, documentTypeAlias, select, propertyLevel, skip, take) {
        let params = {};
        params.skip = skip ? skip : 0;
        params.take = take ? take : 10;
        if (documentTypeAlias)
            params.documentTypeAlias = documentTypeAlias;
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.get(id.toString() + "/children", { params: params });
    }
    static getDescendants(id, documentTypeAlias, select, propertyLevel, skip, take) {
        let params = {};
        params.skip = skip ? skip : 0;
        params.take = take ? take : 10;
        if (documentTypeAlias) {
            params.documentTypeAlias = documentTypeAlias;
        }
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.get(id.toString() + "/descendants", { params: params });
    }
    static getAncestor(id, documentTypeAlias, select, propertyLevel) {
        let params = {};
        if (documentTypeAlias) {
            params.documentTypeAlias = documentTypeAlias;
        }
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.get(id.toString() + "/ancestor", { params: params });
    }
    static search(query, select, propertyLevel, skip, take) {
        let params = {};
        params.query = query;
        params.skip = skip ? skip : 0;
        params.take = take ? take : 10;
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.post("search", params);
    }
    static urls(url, select, propertyLevel) {
        let params = {};
        params.url = url;
        if (select)
            params.select = select;
        if (propertyLevel)
            params.propertyLevel = propertyLevel;
        return this.client.post("urls", params);
    }
}
UmbracoContentApi.baseUrl = "/api/umbraco/content/";
