import { render, staticRenderFns } from "./UmbracoNavigationTab.vue?vue&type=template&id=7bf6ce17&"
import script from "./UmbracoNavigationTab.vue?vue&type=script&lang=ts&"
export * from "./UmbracoNavigationTab.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\_Web\\Business Institute\\Git\\BusinessInstitute\\App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7bf6ce17')) {
      api.createRecord('7bf6ce17', component.options)
    } else {
      api.reload('7bf6ce17', component.options)
    }
    module.hot.accept("./UmbracoNavigationTab.vue?vue&type=template&id=7bf6ce17&", function () {
      api.rerender('7bf6ce17', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/navigation/UmbracoNavigationTab.vue"
export default component.exports