import { Service } from '@jcd-web/basic-service';
import { UmbracoDictionaryApi } from '../api/UmbracoDictionaryApi';
export class UmbracoDictionaryService extends Service {
    static getByLanguage(language) {
        let cacheItem = UmbracoDictionaryService.cache.get('getByLanguage', { language: language });
        if (!cacheItem) {
            cacheItem = UmbracoDictionaryService.cache.add('getByLanguage', { language: language }, UmbracoDictionaryApi.getByLanguage(language).then(function (res) {
                return res.data;
            }));
        }
        return cacheItem.promise;
    }
}
