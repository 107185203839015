var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section book-skims-section", attrs: { id: "book-skims" } },
    [
      _c("div", { attrs: { container: "" } }, [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c("div", { staticClass: "book-skims-list", attrs: { column: "" } }, [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c(
                "div",
                { staticClass: "filter-container", attrs: { column: "l-12" } },
                [
                  _c(
                    "button",
                    {
                      class: { filter: true, active: !_vm.selectedFilter },
                      on: {
                        click: function ($event) {
                          _vm.selectedFilter = null
                        },
                      },
                    },
                    [_vm._v("Alle")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.fetchFilters, function (kategori, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        class: {
                          filter: true,
                          active: _vm.selectedFilter == kategori,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleFilter(kategori)
                          },
                        },
                      },
                      [_vm._v(_vm._s(kategori))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "book-skims-row", attrs: { grid: "wrap" } },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade" } },
                  _vm._l(_vm.filteredBookSkims, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.da.$id,
                        staticClass: "book-skim-col",
                        attrs: { column: "l-3 m-4 s-6 xs-12" },
                      },
                      [
                        _c("div", { staticClass: "book" }, [
                          _c(
                            "div",
                            {
                              staticClass: "book-image",
                              style: [
                                item.da.billede
                                  ? {
                                      backgroundImage:
                                        "url(" + item.da.billede.bookskim + ")",
                                    }
                                  : {
                                      backgroundImage:
                                        "url(" + _vm.defaultImage + ")",
                                    },
                              ],
                            },
                            [
                              _c("div", { staticClass: "book-content" }, [
                                _c("a", { attrs: { href: item.da.url } }, [
                                  _c("div", [
                                    _c("h5", [_vm._v(_vm._s(item.da.bognavn))]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "author" }, [
                                      _vm._v(_vm._s(item.da.forfatter)),
                                    ]),
                                    _vm._v(" "),
                                    item.da.overviewHoverText == ""
                                      ? _c("p", [
                                          _vm._v("Læs Book Skim "),
                                          _c("i", {
                                            staticClass: "mdi mdi-arrow-right",
                                          }),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.da.overviewHoverText !== ""
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(item.da.overviewHoverText) +
                                              " "
                                          ),
                                          _c("i", {
                                            staticClass: "mdi mdi-arrow-right",
                                          }),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.itemsLoaded < _vm.totalItems
              ? _c("div", { staticClass: "load-more" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          _vm.currentPage++
                        },
                      },
                    },
                    [
                      _c("p", [_vm._v("Se flere")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-chevron-down" }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }