var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section events-section", attrs: { id: "events" } },
    [
      _c("div", { attrs: { container: "" } }, [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c("div", { staticClass: "events-list", attrs: { column: "" } }, [
            _c(
              "div",
              { staticClass: "events-row", attrs: { grid: "wrap" } },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade" } },
                  _vm._l(_vm.filteredEvents, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.da.$id,
                        staticClass: "event-col",
                        attrs: { column: "l-3 m-4 s-6 xs-12" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "event-hd",
                            attrs: { href: item.da.url },
                          },
                          [
                            _c("h5", { staticClass: "hd-visible" }, [
                              _vm._v(_vm._s(item.da.name)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "event" }, [
                          _c(
                            "div",
                            {
                              staticClass: "events-image",
                              style: [
                                item.da.billede
                                  ? {
                                      backgroundImage:
                                        "url(" + item.da.billede.bookskim + ")",
                                    }
                                  : {
                                      backgroundImage:
                                        "url(" + _vm.defaultImage + ")",
                                    },
                              ],
                            },
                            [
                              _c("div", { staticClass: "events-content" }, [
                                _c("a", { attrs: { href: item.da.url } }, [
                                  _c("div", [
                                    _c("h5", [_vm._v(_vm._s(item.da.name))]),
                                    _vm._v(" "),
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._f("truncate")(
                                          item.da.teaserTekstSourceCode,
                                          100
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "author" }, [
                                      _c("b", [_vm._v("Fra: ")]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(item.da.startdato)
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "author" }, [
                                      _c("b", [_vm._v("Til: ")]),
                                      _vm._v(
                                        _vm._s(_vm.formatDate(item.da.slutdato))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("Se event "),
                                      _c("i", {
                                        staticClass: "mdi mdi-arrow-right",
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.itemsLoaded < _vm.totalItems
              ? _c("div", { staticClass: "load-more" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          _vm.currentPage++
                        },
                      },
                    },
                    [
                      _c("p", [_vm._v("Se flere")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-chevron-down" }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }