var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { UmbracoContentService } from "@jcd-web/basic-umbraco";
import * as moment from 'moment';
let BiProduction = class BiProduction extends Vue {
    constructor() {
        super(...arguments);
        // VARS
        this.selectedFilter = null;
        this.articlesPrPage = 12;
        this.currentPage = 1;
        this.defaultImage = "/app/dist/assets/images/default.jpg";
        this.itemsLoaded = 0;
        this.totalItems = 0;
        this.articlesSource = [];
    }
    //If a umbraco field sortDate (datetime) is unset, it returns the lowest possible value
    //Check to see if item has a sortDate, if not use createDate to sort
    getSortDate(item) {
        const minDate = moment.utc("0001-01-01");
        if (moment.utc(item.da.sortDate).isSameOrAfter(minDate)) {
            return item.da.sortDate;
        }
        else {
            return item.createDate;
        }
    }
    get filteredArticles() {
        let result;
        result = this.articlesSource.filter(x => x.documentTypeAlias == this.selectedFilter || !this.selectedFilter
            ? true
            : false).sort((a, b) => new Date(this.getSortDate(b)).getTime() -
            new Date(this.getSortDate(a)).getTime());
        this.totalItems = result.length; //total number of items with the applied filter
        result = result.slice(0, this.currentPage * this.articlesPrPage);
        this.itemsLoaded = result.length; //items loaded into view with applied filter
        return result;
    }
    get fetchFilters() {
        const articlesWithDocType = this.articlesSource.filter(x => (x.documentTypeAlias ? true : false));
        let result = [
            ...new Set(articlesWithDocType.map(item => item.documentTypeAlias))
        ];
        return result;
    }
    mounted() {
        const fetchProperties = [
            "titel",
            "teaserTekstSourceCode",
            "forfatter",
            "billede",
            "dato",
            "kilde",
            "documentTypeAlias",
            "createDate",
            "sortDate"
        ];
        UmbracoContentService.getChildren(this.source, "", fetchProperties, 2, 0, 1000).then(res => {
            let test = res;
            this.articlesSource = test.results;
        }).catch(err => { });
    }
    toggleFilter(filter) {
        this.currentPage = 1;
        this.selectedFilter = filter;
    }
    changeFilterName(doctype) {
        if (doctype === "report") {
            return "Rapporter";
        }
        else if (doctype === "article") {
            return "Artikler";
        }
        else if (doctype === "orientation") {
            return "Orientering";
        }
        else {
            return "Dogmer";
        }
    }
    changeName(name) {
        if (name === "report") {
            return "rapport";
        }
        else if (name === "article") {
            return "artikel";
        }
        else if (name === "orientation") {
            return "orientering";
        }
        else {
            return "dogme";
        }
    }
};
BiProduction = __decorate([
    Component({
        props: {
            source: Number
        }
    })
], BiProduction);
export default BiProduction;
