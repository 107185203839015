var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showForm
      ? _c("div", [_vm._t("default")], 2)
      : _c(
          "div",
          [
            _c("p", [_vm._v(_vm._s(_vm.submitMessage))]),
            _vm._v(" "),
            _vm._t("content"),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }