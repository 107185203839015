var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-navigation-menu" }, [
    _vm.loaded ? _c("div", { staticClass: "breadcrumb-wrapper" }) : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "level active" },
      _vm._l(_vm.cultures, function (item) {
        return _c("li", { staticClass: "item" }, [
          _c("a", { staticClass: "link", attrs: { href: item.url } }, [
            _vm._v(_vm._s(item.displayName)),
          ]),
        ])
      }),
      0
    ),
    _vm._v(" "),
    !_vm.loaded
      ? _c("div", { staticClass: "loader-wrapper" }, [
          _c("div", { staticClass: "loader large" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }