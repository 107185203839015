var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section content-renderer-section" },
    [
      !!_vm.fetchFilters && _vm.fetchFilters.length > 0
        ? _c("div", { attrs: { grid: "wrap" } }, [
            _c(
              "div",
              { staticClass: "filter-container", attrs: { column: "l-12" } },
              [
                _c(
                  "button",
                  {
                    class: { filter: true, active: !_vm.selectedFilter },
                    on: {
                      click: function ($event) {
                        _vm.selectedFilter = null
                      },
                    },
                  },
                  [_vm._v("Alle")]
                ),
                _vm._v(" "),
                _vm._l(_vm.fetchFilters, function (filter, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      class: {
                        filter: true,
                        active: _vm.selectedFilter == filter,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFilter(filter)
                        },
                      },
                    },
                    [_vm._v(_vm._s(filter))]
                  )
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.rows, function (row, index) {
        return _c(
          "div",
          { key: index, staticClass: "masonry-grid-container" },
          _vm._l(row.columns, function (column, ci) {
            return _c(
              "div",
              { key: ci, class: { "left-ish": ci == 0, "right-ish": ci == 1 } },
              _vm._l(column.items, function (article, ai) {
                return _c(
                  "div",
                  {
                    key: ai,
                    staticClass: "brick",
                    class: {
                      "show-image": article.documentTypeAlias == "biEmployee",
                    },
                  },
                  [
                    _c("content-renderer-item", {
                      attrs: { article: article },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          }),
          0
        )
      }),
      _vm._v(" "),
      _vm.itemsLoaded < _vm.totalItems
        ? _c("div", { staticClass: "load-more" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    _vm.currentPage++
                  },
                },
              },
              [
                _c("p", [_vm._v("Se flere")]),
                _vm._v(" "),
                _c("i", { staticClass: "mdi mdi-chevron-down" }),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }