var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { MailService } from 'services/MailService';
import { UmbracoDictionaryService } from "../services/umbraco";
import { Contact } from '../models/Contact';
import Loader from "../components/utility/Loader.vue";
import { State } from "../config/State";
let BusinessContactForm = class BusinessContactForm extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.success = false;
        this.submitted = false;
        this.contact = new Contact();
        this.dictionary = {};
    }
    submit() {
        this.contact.pageId = this.pageId;
        this.contact.subject = this.subject;
        this.submitted = true;
        this.loading = true;
        MailService.contact(this.contact).then(() => {
            this.success = true;
            this.loading = false;
        }, err => {
            this.success = false;
            this.loading = false;
        });
    }
    reset() {
        this.loading = false;
        this.success = false;
        this.submitted = false;
        this.contact = new Contact();
    }
    mounted() {
        UmbracoDictionaryService.getByLanguage(State.language).then(items => {
            this.dictionary = items;
        });
    }
};
BusinessContactForm = __decorate([
    Component({
        props: {
            pageId: String,
            description: String,
            headline: String,
            subject: String,
            successMessage: String,
            errorMessage: String,
            privacyPolicy: String
        },
        components: {
            Loader
        }
    })
], BusinessContactForm);
export default BusinessContactForm;
