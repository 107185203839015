var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ToastList from "@jcd-web/basic-toast";
import VideoBackground from "@jcd-web/basic-video-background";
import ProductGallery from "@jcd-web/basic-product-gallery";
import CookieMessage from "@jcd-web/basic-cookie-message";
import BasicMobileNavigation from "components/navigation/BasicMobileNavigation.vue";
import LanguageSelector from "components/navigation/LanguageSelector.vue";
//Business
import BusinessBasicMobileNavigation from "components/navigation/BusinessBasicMobileNavigation.vue";
import CallToAction from "components/CallToAction.vue";
import ContactForm from "components/ContactForm.vue";
import BookSkims from "components/BookSkims.vue";
import Events from "components/Events.vue";
import BiProduction from "components/BiProduction.vue";
import ContentRenderer from "components/ContentRenderer.vue";
import Podcasts from "components/Podcasts.vue";
import Articles from "components/Articles.vue";
import SideMenu from "components/SideMenu.vue";
import BusinessContactForm from "components/BusinessContactForm.vue";
import BureauetCookiebotDeclaration from "components/BureauetCookiebotDeclaration.vue";
import Search from "components/Search.vue";
import InfoSlider from "components/InfoSlider.vue";
import Modal from "../components/utility/Modal.vue";
import UmbracoForm from "../components/UmbracoForm.vue";
import { State } from "config/State";
import { directive as onClickaway } from "vue-clickaway";
import { debounce } from "ts-debounce";
Vue.component("v-style", {
    render: function (createElement) {
        return createElement("style", this.$slots.default);
    },
});
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.loaded = false;
        this.formModalVisible = false;
        this.windowWidth = 0;
        this.resizeDebouncer = debounce(this.changePadding, 100, { isImmediate: false });
        this.backgroundResizeDebouncer = debounce(this.changeResponsiveImage, 100, {
            isImmediate: false,
        });
        this.showMegaMenu = null;
        this.showSideMenu = false;
        this.sideMenuToShow = "";
        this.languageSelectorVisible = false;
        this.searchInputVisible = false;
    }
    created() {
        State.language = this.language;
        State.countLang = this.countLang;
        this.navigationTabs = [
            {
                id: "menu",
                iconActive: "mdi mdi-close",
                iconInactive: "mdi mdi-menu",
                componentName: "umbraco-navigation-tab",
                componentOptions: {
                    rootId: this.rootId,
                    currentId: this.currentId,
                    rootName: this.rootName,
                },
            },
        ];
        if (this.settings &&
            this.settings.cultures &&
            this.settings.cultures.length <= 1)
            this.navigationTabs.pop();
        //business
        this.businessNavigationTabs = [
            {
                id: "menu",
                iconActive: "mdi mdi-close",
                iconInactive: "mdi mdi-menu",
                componentName: "business-umbraco-navigation-tab",
                componentOptions: {
                    rootId: this.rootId,
                    currentId: this.currentId,
                    rootName: this.rootName,
                },
            },
        ];
        if (this.settings &&
            this.settings.cultures &&
            this.settings.cultures.length <= 1)
            this.businessNavigationTabs.pop();
    }
    changeResponsiveImage() {
        let viewport = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.windowWidth = viewport;
        if (viewport > 1100) {
            this.responsiveImageSwitcher("background-image-xlarge");
        }
        else if (viewport > 768) {
            this.responsiveImageSwitcher("background-image-large");
        }
        else if (viewport > 480) {
            this.responsiveImageSwitcher("background-image-medium");
        }
        else {
            this.responsiveImageSwitcher("background-image-small");
        }
    }
    mounted() {
        this.loaded = true;
        let self = this;
        self.changePadding();
        self.changeResponsiveImage();
        window.addEventListener("resize", self.resizeDebouncer);
        window.addEventListener("resize", self.backgroundResizeDebouncer);
        this.ansogTextFix();
    }
    responsiveImageSwitcher(imgAttr) {
        let smallBGs = document.querySelectorAll("*[" + imgAttr + "]");
        for (var i = 0; i < smallBGs.length; i++) {
            let currentElement = smallBGs[i];
            let targeSrc = currentElement.getAttribute(imgAttr);
            currentElement.style.backgroundImage = "url('" + targeSrc + "')";
        }
    }
    changePadding() {
        let viewport = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        this.windowWidth = viewport;
        if (viewport > 960) {
            this.responsivePaddingChanger("data-padding-large");
        }
        else if (viewport > 768) {
            this.responsivePaddingChanger("data-padding-medium");
        }
        else {
            this.responsivePaddingChanger("data-padding-small");
        }
    }
    responsivePaddingChanger(dataPaddingAttr) {
        let btnTiles = document.querySelectorAll("*[" + dataPaddingAttr + "]");
        for (var i = 0; i < btnTiles.length; i++) {
            let currentElement = btnTiles[i];
            let tilePaddingValue = currentElement.getAttribute(dataPaddingAttr);
            currentElement.style.padding = tilePaddingValue + "px 0";
        }
    }
    toggleDropdown(menu) {
        if (menu == this.showMegaMenu) {
            this.showMegaMenu = null;
        }
        else {
            this.showMegaMenu = menu;
        }
    }
    closeDropdown() {
        this.showMegaMenu = null;
    }
    toggleSideMenu(show, btnId) {
        if (show) {
            let vm = this;
            setTimeout(function () {
                vm.sideMenuToShow = btnId;
            }, 50);
        }
    }
    closeSideMenu() {
        this.sideMenuToShow = "";
    }
    toggleLanguageSelector() {
        if (this.languageSelectorVisible)
            this.languageSelectorVisible = false;
        else
            this.languageSelectorVisible = true;
    }
    ansogTextFix() {
        let labelThing = document.querySelector("label[for='1dd954a1-7910-4cbf-9b82-d3e47bfe895f_7']");
        if (labelThing) {
            labelThing.innerHTML = "MBA²";
        }
    }
    toggleSearchVisibility() {
        setTimeout(() => {
            this.searchInputVisible = false;
        }, 150);
    }
};
App = __decorate([
    Component({
        components: {
            ToastList,
            VideoBackground,
            ProductGallery,
            CookieMessage,
            BasicMobileNavigation,
            LanguageSelector,
            CallToAction,
            ContactForm,
            BusinessContactForm,
            BureauetCookiebotDeclaration,
            BookSkims,
            Podcasts,
            Articles,
            SideMenu,
            BiProduction,
            ContentRenderer,
            Events,
            Modal,
            UmbracoForm,
            BusinessBasicMobileNavigation,
            Search,
            InfoSlider
        },
        props: {
            rootId: Number,
            currentId: Number,
            language: String,
            countLang: Number,
            rootName: String,
            settings: Object,
        },
        directives: {
            onClickaway,
        },
    })
], App);
export default App;
