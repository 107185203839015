var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import * as _ from 'lodash';
import { TreeNavigationItem } from "@jcd-web/basic-navigation";
import { UmbracoContentService } from "@jcd-web/basic-umbraco";
import { State } from "config/State";
import BusinessTreeNavigation from "components/navigation/BusinessTreeNavigation.vue";
let BusinessNavigationMenuTab = class BusinessNavigationMenuTab extends Vue {
    constructor() {
        super(...arguments);
        this.navigationItems = [];
        this.loaded = false;
    }
    mounted() {
        let languageExamineSuffix = State.countLang > 1 ? "_" + State.language : "";
        let language = State.language;
        let query = `\
        __Path:\\-1\\,${this.options.rootId}* \
        +__Published:y \
        -__NodeTypeAlias:newsArticle \
        -__NodeTypeAlias:fourOhFourPage \
        -umbracoNaviHide:1 \
        `;
        // +__Published${languageExamineSuffix}:y \
        // -umbracoNaviHide${languageExamineSuffix}:1 \
        UmbracoContentService.search(query, [
            "id",
            "documentTypeAlias",
            "name",
            "parentId",
            "url",
            "path",
            "alternativePageName",
            "sortOrder"
        ], 1, 0, 10000).then(content => {
            let nodes = content; // skal rettes i servicen
            let result = _.orderBy(nodes.results, function (e) { return e.sortOrder; });
            // console.log('sorted', result);
            this.navigationItems = TreeNavigationItem.connect(result.map(x => ({
                id: x.id,
                name: !!x[language].alternativePageName
                    ? x[language].alternativePageName
                    : x[language].name,
                url: x[language].url,
                parentId: x.parentId,
                type: x.documentTypeAlias
            })));
            if (this.navigationItems.findIndex(x => x.id === this.options.currentId) ===
                -1) {
                this.options.currentId = this.options.rootId;
            }
            this.loaded = true;
        });
    }
};
BusinessNavigationMenuTab = __decorate([
    Component({
        props: {
            options: Object
        },
        components: {
            BusinessTreeNavigation
        }
    })
], BusinessNavigationMenuTab);
export default BusinessNavigationMenuTab;
