var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      { staticClass: "article-link", attrs: { href: _vm.article.da.url } },
      [
        _c("div", { staticClass: "hover-overlay" }, [
          _vm.article.documentTypeAlias === "interview"
            ? _c("div", [
                _vm.article.da.overskrift
                  ? _c("h5", [_vm._v(_vm._s(_vm.article.da.overskrift))])
                  : _c("h5", [_vm._v(_vm._s(_vm.article.da.name))]),
              ])
            : _c("div", [_c("h5", [_vm._v(_vm._s(_vm.article.da.name))])]),
          _vm._v(" "),
          _c("p", [
            _vm._v(_vm._s(_vm.buttonName(_vm.article.documentTypeAlias)) + " "),
            _c("i", { staticClass: "mdi mdi-arrow-right" }),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.article.documentTypeAlias === "biEmployee"
      ? _c("div", {
          staticClass: "portrait",
          style: [
            _vm.article.da.portrait
              ? {
                  backgroundImage:
                    "url(" + _vm.article.da.portrait.gridlarge + ")",
                }
              : { backgroundImage: "url(" + _vm.defaultImage + ")" },
          ],
        })
      : _c("div", {
          staticClass: "image",
          style: [
            _vm.article.da.billede
              ? {
                  backgroundImage:
                    "url(" + _vm.article.da.billede.gridlarge + ")",
                }
              : { backgroundImage: "url(" + _vm.defaultImage + ")" },
          ],
        }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content",
        style: [
          _vm.article.da.baggrundsfarve
            ? { backgroundColor: "#" + _vm.article.da.baggrundsfarve }
            : { backgroundColor: "#fff" },
          _vm.article.da.tekstfarve
            ? { color: "#" + _vm.article.da.tekstfarve }
            : { color: "#000" },
        ],
      },
      [
        _vm.article.documentTypeAlias !== "biEmployee"
          ? _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.contentType(_vm.article.documentTypeAlias)) +
                  "\n        "
              ),
              _vm.article.documentTypeAlias === "interview"
                ? _c("span", [_vm._v(_vm._s(_vm.article.da.uddannelse))])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.article.documentTypeAlias === "interview" &&
        _vm.article.da.overskrift
          ? _c("h5", [_vm._v(_vm._s(_vm.article.da.overskrift))])
          : _c("h5", [_vm._v(_vm._s(_vm.article.da.name))]),
        _vm._v(" "),
        _c("div", [
          _vm.article.da.teaserTekstSourceCode
            ? _c("p", {
                staticClass: "teaser",
                domProps: {
                  innerHTML: _vm._f("truncate")(
                    _vm.article.da.teaserTekstSourceCode,
                    180
                  ),
                },
              })
            : _c("p", { staticClass: "teaser" }, [
                _vm._v(
                  _vm._s(_vm._f("truncate")(_vm.article.da.teaserTekst, 180))
                ),
              ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }