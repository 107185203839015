var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.navigationItems.length > 0
    ? _c("business-tree-navigation", {
        attrs: {
          "root-id": _vm.options.rootId,
          "current-id": _vm.options.currentId,
          items: _vm.navigationItems,
          loaded: _vm.loaded,
          "root-name": _vm.options.rootName,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }