var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dictionary
      ? _c(
          "div",
          { staticClass: "search-form" },
          [
            _c(
              "form",
              {
                attrs: { action: "get" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "field-wrapper" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search.query,
                        expression: "search.query",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.dictionary["searchInputPlaceholder"],
                      required: "",
                    },
                    domProps: { value: _vm.search.query },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.search, "query", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.dictionary["searchButtonText"]))]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("loader", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "medium" },
            }),
            _vm._v(" "),
            _vm.success
              ? _c("div", [
                  _vm.searchResultLimit &&
                  _vm.searchResultLimit.length > 0 &&
                  _vm.results.length > 0
                    ? _c("div", { staticClass: "showing-items" }, [
                        _c("p", [
                          _vm._v(
                            "Viser " +
                              _vm._s(_vm.searchResultLimit.length) +
                              " ud af " +
                              _vm._s(_vm.results.length) +
                              " resultater"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "search-results" },
                    _vm._l(_vm.searchResultLimit, function (result) {
                      return _c(
                        "li",
                        { key: result.url, staticClass: "search-result" },
                        [
                          _c("span", { staticClass: "icon-wrapper" }, [
                            result.documentTypeAlias == "interview" ||
                            result.documentTypeAlias == "interviews"
                              ? _c("i", {
                                  staticClass: "mdi mdi-microphone-outline",
                                })
                              : result.documentTypeAlias == "education"
                              ? _c("i", { staticClass: "mdi mdi-school" })
                              : result.documentTypeAlias == "bookSkim" ||
                                result.documentTypeAlias == "bookSkims" ||
                                result.documentTypeAlias == "articleReview"
                              ? _c("i", {
                                  staticClass: "mdi mdi-book-open-page-variant",
                                })
                              : result.documentTypeAlias == "biEmployee"
                              ? _c("i", { staticClass: "mdi mdi-account" })
                              : result.documentTypeAlias == "event" ||
                                result.documentTypeAlias == "events"
                              ? _c("i", {
                                  staticClass: "mdi mdi-calendar-check",
                                })
                              : _c("i", {
                                  staticClass: "mdi mdi-file-document-outline",
                                }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _c("a", { attrs: { href: result.url } }, [
                              _c("h3", { staticClass: "subtitle2" }, [
                                _vm._v(_vm._s(result.name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "url",
                                attrs: { href: result.url },
                              },
                              [
                                _c("small", [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.host) +
                                      _vm._s(result.da.url) +
                                      "\n                                "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            result.metaDescription
                              ? _c("p", [
                                  _c("small", [
                                    _vm._v(_vm._s(result.metaDescription)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.searchResultLimit < _vm.results
                    ? _c("div", { staticClass: "load-more" }, [
                        _vm.searchResultLimit &&
                        _vm.searchResultLimit.length > 0 &&
                        _vm.results.length > 0
                          ? _c("div", { staticClass: "showing-items" }, [
                              _c("p", [
                                _vm._v(
                                  "Viser " +
                                    _vm._s(_vm.searchResultLimit.length) +
                                    " ud af " +
                                    _vm._s(_vm.results.length) +
                                    " resultater"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                _vm.currentPage++
                              },
                            },
                          },
                          [
                            _c("p", [_vm._v("Indlæs flere")]),
                            _vm._v(" "),
                            _c("i", { staticClass: "mdi mdi-chevron-down" }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.results &&
                  _vm.results.length === 0 &&
                  _vm.submitted &&
                  !_vm.loading
                    ? _c("div", [
                        _c("div", { staticClass: "no-results-wrapper" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.dictionary["noResults"])),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-wrapper" }, [
      _c("i", { staticClass: "mdi mdi-magnify" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }