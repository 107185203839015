var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from "./utility/Loader.vue";
import "../filters/dates";
import { UmbracoDictionaryService, UmbracoContentService } from "../services/umbraco";
import { State } from "../config/State";
import { Search as SearchModel } from "../models/search/Search";
let Search = class Search extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.success = false;
        this.submitted = false;
        this.search = new SearchModel();
        this.results = [];
        this.dictionary = null;
        this.currentPage = 1;
        this.resultsPerPage = 10;
    }
    submit() {
        this.search.rootId = this.rootId;
        this.search.pageSize = 20;
        if (this.search.query) {
            this.submitted = true;
            this.loading = true;
            const query = this.search.query.replace('å', 'a').replace('ø', 'o').replace('æ', 'ae');
            UmbracoContentService.search(`+path:\\` + this.sitePath + `* +(
          nodeName:(${query}*) 
          bodyText:(${query})
          metaKeywords:(${query}) 
          metaDescription:(${query}) 
        )
        +apiHide:0`, ['url', 'name', 'bodyText', 'updateDate', 'keywords', 'path', 'metaKeywords', 'metaDescription', 'documentTypeAlias'], 1, 0, 9999
            // `+path:\\` + this.sitePath + `* +(
            //   nodeName:(${this.search.query}*) 
            //   bodyText:(${this.search.query})
            //   metaKeywords:(${this.search.query}) 
            //   metaDescription:(${this.search.query}) 
            // )
            // +umbracoNaviHide:0`,
            // ['url', 'name', 'bodyText', 'updateDate', 'keywords', 'path', 'metaKeywords', 'metaDescription', 'documentTypeAlias'],
            // 1,
            // 0,
            // 9999
            )
                .then(res => {
                let results = res;
                this.results = results.results;
                this.success = true;
                this.loading = false;
                // console.log('contentservice then', this.results);
            })
                .catch(err => {
                console.log('contentservice catch', err);
                this.loading = false;
                this.success = false;
            });
        }
        else {
        }
    }
    mounted() {
        this.search.rootId = this.rootId;
        this.search.query = this.search.query == "" ? this.search.query : this.query;
        this.search.pageSize = 20;
        if (this.search.query !== "") {
            this.submit();
        }
        UmbracoDictionaryService.getByLanguage(State.language).then(items => {
            this.dictionary = items;
        });
    }
    get searchResultLimit() {
        if (this.results.length > 0) {
            let searchResults = this.results.slice(0, this.currentPage * this.resultsPerPage);
            return searchResults;
        }
    }
};
Search = __decorate([
    Component({
        props: {
            rootId: Number,
            query: String,
            sitePath: String,
            host: String
        },
        components: {
            Loader
        }
    })
], Search);
export default Search;
