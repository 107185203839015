import { Service } from "@jcd-web/basic-service";
import { UmbracoMediaApi } from "../api/UmbracoMediaApi";
export class UmbracoMediaService extends Service {
    static get(id, select, propertyLevel) {
        return UmbracoMediaApi.get(id, select != null ? select.join(",") : "", propertyLevel).then(function (res) {
            return res.data;
        });
    }
    static getChildren(id, documentTypeAlias, select, propertyLevel, skip, take) {
        return UmbracoMediaApi.getChildren(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
    static getDescendants(id, documentTypeAlias, select, propertyLevel, skip, take) {
        return UmbracoMediaApi.getDescendants(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
    static getAncestor(id, documentTypeAlias, select, propertyLevel) {
        return UmbracoMediaApi.getAncestor(id, documentTypeAlias, select != null ? select.join(",") : "", propertyLevel).then(function (res) {
            return res.data;
        });
    }
    static search(query, select, propertyLevel, skip, take) {
        return UmbracoMediaApi.search(query, select, propertyLevel, skip, take).then(function (res) {
            return res.data;
        });
    }
}
