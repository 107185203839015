var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section podcasts-section", attrs: { id: "podcasts" } },
    [
      _c("div", { attrs: { container: "" } }, [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c("div", { staticClass: "podcasts-list", attrs: { column: "" } }, [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c(
                "div",
                { staticClass: "filter-container", attrs: { column: "l-12" } },
                [
                  _c(
                    "button",
                    {
                      class: { filter: true, active: !_vm.selectedFilter },
                      on: {
                        click: function ($event) {
                          _vm.selectedFilter = null
                        },
                      },
                    },
                    [_vm._v("Alle")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.fetchFilters, function (podcast, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        class: {
                          filter: true,
                          active: _vm.selectedFilter == podcast,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleFilter(podcast)
                          },
                        },
                      },
                      [_vm._v(_vm._s(podcast))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "podcasts-row", attrs: { grid: "wrap" } },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade" } },
                  _vm._l(_vm.filteredPodcasts, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.da.$id,
                        staticClass: "podcast-col",
                        attrs: { column: "l-6 m-12 s-12 xs-12" },
                      },
                      [
                        _c("a", { attrs: { href: item.da.url } }, [
                          _c("div", { staticClass: "podcast" }, [
                            _c("div", { attrs: { grid: "wrap" } }, [
                              _c("div", { attrs: { column: "5" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "podcast-image",
                                    style: [
                                      item.da.billede
                                        ? {
                                            backgroundImage:
                                              "url(" +
                                              item.da.billede.podcast +
                                              ")",
                                          }
                                        : {
                                            backgroundImage:
                                              "url(" + _vm.defaultImage + ")",
                                          },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "podcast-content" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "mdi mdi-play-circle-outline",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { attrs: { column: "7" } }, [
                                _c("div", { staticClass: "podcast-text" }, [
                                  _c("h5", { staticClass: "date" }, [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(item.da.dato))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  item.da.titel
                                    ? _c("h5", [_vm._v(_vm._s(item.da.titel))])
                                    : _c("h5", [_vm._v(_vm._s(item.da.name))]),
                                  _vm._v(" "),
                                  item.da.teaserTekstSourceCode
                                    ? _c("p", {
                                        domProps: {
                                          innerHTML: _vm._f("truncate")(
                                            item.da.teaserTekstSourceCode,
                                            100
                                          ),
                                        },
                                      })
                                    : _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("truncate")(
                                              item.da.teaserTekst,
                                              100
                                            )
                                          )
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "listen" }, [
                                    _vm._v(
                                      "\n                                                    Lyt til afsnit\n                                                    "
                                    ),
                                    _c("i", {
                                      staticClass: "mdi mdi-arrow-right",
                                    }),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.itemsLoaded < _vm.totalItems
              ? _c("div", { staticClass: "load-more" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          _vm.currentPage++
                        },
                      },
                    },
                    [
                      _c("p", [_vm._v("Se flere")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-chevron-down" }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }