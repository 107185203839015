var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let BusinessTreeNavigation = class BusinessTreeNavigation extends Vue {
    constructor() {
        super(...arguments);
        this.rootItem = null;
        this.activeItem = null;
        this.disabledIds = [1338, 1355, 1356, 2464, 1098, 1104];
    }
    selectItem(item) {
        this.activeItem = item;
    }
    mounted() {
        this.rootItem = this.items.filter(x => x.id == this.rootId)[0];
        this.activeItem = this.items.filter(x => x.id == this.currentId)[0];
        if (this.activeItem.children.length <= 0) {
            if (this.activeItem.parent == undefined) {
                this.activeItem = this.rootItem;
            }
            else {
                this.activeItem = this.activeItem.parent;
            }
        }
    }
};
BusinessTreeNavigation = __decorate([
    Component({
        props: {
            rootId: [Number, String],
            currentId: [Number, String],
            items: Array,
            loaded: {
                type: Boolean,
                default: true
            },
            rootName: {
                type: String,
                default: ''
            }
        }
    })
], BusinessTreeNavigation);
export default BusinessTreeNavigation;
