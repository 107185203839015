var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-navigation-menu" },
    [
      _vm.loaded
        ? _c(
            "div",
            {
              staticClass: "breadcrumb-wrapper",
              on: {
                click: function ($event) {
                  _vm.activeItem && _vm.activeItem.parent
                    ? _vm.selectItem(_vm.activeItem.parent)
                    : null
                },
              },
            },
            [
              _vm.activeItem && _vm.activeItem.parent
                ? _c("i", {
                    staticClass: "mdi mdi-chevron-left",
                    class: { active: _vm.activeItem && _vm.activeItem.parent },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.activeItem
                ? _c(
                    "div",
                    { staticClass: "breadcrumb" },
                    [
                      _c(
                        "a",
                        {
                          class: {
                            active:
                              _vm.activeItem && _vm.activeItem == _vm.rootItem,
                          },
                        },
                        [_vm._v(_vm._s(_vm.rootName))]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.items, function (item, i) {
                        return _c(
                          "a",
                          {
                            key: i,
                            class: {
                              active:
                                _vm.activeItem &&
                                _vm.activeItem != _vm.rootItem &&
                                _vm.activeItem == item,
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, i) {
        return _vm.loaded
          ? _c(
              "ul",
              {
                key: i,
                staticClass: "level",
                class: { active: _vm.activeItem == item },
              },
              _vm._l(item.children, function (subItem, i) {
                return _c(
                  "li",
                  { key: i, staticClass: "item" },
                  [
                    subItem.children.length > 0 &&
                    _vm.disabledIds.indexOf(subItem.id) > -1
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "link only-sublevel",
                              on: {
                                click: function ($event) {
                                  return _vm.selectItem(subItem)
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(subItem.name) + " "),
                              _c("i", { staticClass: "mdi mdi-chevron-right" }),
                            ]
                          ),
                        ]
                      : subItem.children.length > 0 && subItem.id === 2367
                      ? [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: subItem.url },
                            },
                            [_vm._v(_vm._s(subItem.name))]
                          ),
                        ]
                      : [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: subItem.url },
                            },
                            [_vm._v(_vm._s(subItem.name))]
                          ),
                          _vm._v(" "),
                          subItem.children.length > 0
                            ? _c("a", {
                                staticClass:
                                  "mdi mdi-chevron-right sublevel-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectItem(subItem)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e()
      }),
      _vm._v(" "),
      !_vm.loaded
        ? _c("div", { staticClass: "loader-wrapper" }, [
            _c("div", { staticClass: "loader large" }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }