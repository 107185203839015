var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueTinySlider from "vue-tiny-slider";
let InfoSlider = class InfoSlider extends Vue {
    constructor() {
        super(...arguments);
        this.tinysliderOptions = {};
    }
    created() {
        const vm = this;
        const desktopSlidesInt = parseInt(this.desktopSlides);
        const tabletSlidesInt = parseInt(this.tabletSlides);
        let responsiveObject = {};
        if (desktopSlidesInt > 4) {
            responsiveObject = {
                768: {
                    items: tabletSlidesInt,
                },
                992: {
                    items: 4,
                },
                1200: {
                    items: desktopSlidesInt,
                },
            };
        }
        else {
            responsiveObject = {
                768: {
                    items: tabletSlidesInt,
                },
                992: {
                    items: desktopSlidesInt,
                },
            };
        }
        this.tinysliderOptions = {
            mouseDrag: true,
            speed: 500,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            autoplay: false,
            arrowKeys: true,
            loop: true,
            rewind: false,
            controls: true,
            startIndex: 0,
            nav: false,
            items: 1,
            responsive: responsiveObject,
            controlsText: [
                '<i class="mdi mdi-chevron-left" style="color:#' +
                    vm.arrowColor +
                    '"></i>',
                '<i class="mdi mdi-chevron-right" style="color:#' +
                    vm.arrowColor +
                    '"></i>',
            ],
        };
    }
};
InfoSlider = __decorate([
    Component({
        props: {
            desktopSlides: String,
            tabletSlides: String,
            arrowColor: String,
        },
        components: {
            "tiny-slider": VueTinySlider,
        },
    })
], InfoSlider);
export default InfoSlider;
