import '@babel/polyfill';
import 'styles/main';
import 'utilities/detect-ie';
window.$ = window.jQuery = require("jquery");
require("jquery-validation");
require("jquery-validation-unobtrusive");
import Vue from 'vue';
import App from 'components/App.vue';
Vue.filter('truncate', function (text, stop, clamp) {
    if (text) {
        return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    }
    return "";
});
export const EventBus = new Vue();
new Vue({
    el: '#app',
    components: {
        App
    }
});
