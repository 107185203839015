var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let UmbracoForm = class UmbracoForm extends Vue {
    constructor() {
        super(...arguments);
        this.showForm = true;
    }
    mounted() {
        this.form = $(this.$el).find("form");
        const vm = this;
        if (vm.form != undefined) {
            vm.form.submit(function (e) {
                e.preventDefault();
                const $this = $(this), hasErrors = $this.find(".field-validation-error").length > 0;
                $this.find(".umbraco-forms-response").remove();
                if (!hasErrors) {
                    $.ajax({
                        url: $this.attr("action"),
                        type: "POST",
                        cache: false,
                        data: $this.serialize(),
                        success: function (data) {
                            //If the submit message exists, everything went well
                            const $message = $(data).find(".umbraco-forms-submitmessage");
                            if ($message.length > 0) {
                                vm.showForm = false;
                                vm.submitMessage = $message.html();
                            }
                        },
                        error: function (data) {
                            console.error("Error posting form", data);
                        }
                    });
                }
            });
        }
    }
};
UmbracoForm = __decorate([
    Component({})
], UmbracoForm);
export default UmbracoForm;
