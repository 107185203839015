var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showModal,
            expression: "showModal",
          },
        ],
        staticClass: "modal",
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-container",
            style: { "max-width": _vm.modalWidth },
          },
          [
            _c("div", { staticClass: "modal-header" }, [_vm._t("header")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }