var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CallToAction from "components/CallToAction.vue";
import BusinessContactForm from "components/BusinessContactForm.vue";
import Modal from "../components/utility/Modal.vue";
import { directive as onClickaway } from "vue-clickaway";
let SideMenu = class SideMenu extends Vue {
    constructor() {
        super(...arguments);
        //vars
        this.tileToShow = "";
        this.formModalVisible = false;
    }
    toggleSideMenuRenderer(show, tileId) {
        if (show) {
            let vm = this;
            setTimeout(function () {
                vm.tileToShow = tileId;
            }, 50);
        }
    }
    closeSideMenuRenderer() {
        this.tileToShow = "";
    }
};
SideMenu = __decorate([
    Component({
        props: {},
        components: {
            CallToAction,
            BusinessContactForm,
            Modal
        },
        directives: {
            onClickaway
        },
    })
], SideMenu);
export default SideMenu;
