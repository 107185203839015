var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section bi-prod-section", attrs: { id: "bi-prod" } },
    [
      _c("div", { attrs: { container: "" } }, [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c("div", { staticClass: "bi-prod-list", attrs: { column: "" } }, [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c(
                "div",
                { staticClass: "filter-container", attrs: { column: "l-12" } },
                [
                  _c(
                    "button",
                    {
                      class: { filter: true, active: !_vm.selectedFilter },
                      on: {
                        click: function ($event) {
                          _vm.selectedFilter = null
                        },
                      },
                    },
                    [_vm._v("Alle")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.fetchFilters, function (doctype, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        class: {
                          filter: true,
                          active: _vm.selectedFilter == doctype,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleFilter(doctype)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.changeFilterName(doctype)))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bi-prod-row", attrs: { grid: "wrap" } },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade" } },
                  _vm._l(_vm.filteredArticles, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.da.$id,
                        staticClass: "bi-prod-col",
                        attrs: { column: "l-3 m-4 s-6 xs-12" },
                      },
                      [
                        _c("div", { staticClass: "bi-prod-article" }, [
                          _c(
                            "div",
                            {
                              staticClass: "bi-prod-image",
                              style: [
                                item.da.billede
                                  ? {
                                      backgroundImage:
                                        "url(" + item.da.billede.bookskim + ")",
                                    }
                                  : {
                                      backgroundImage:
                                        "url(" + _vm.defaultImage + ")",
                                    },
                              ],
                            },
                            [
                              _c("div", { staticClass: "bi-prod-content" }, [
                                _c("a", { attrs: { href: item.da.url } }, [
                                  _c("div", [
                                    _c("h5", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.da.name)),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass: "author",
                                      domProps: {
                                        innerHTML: _vm._f("truncate")(
                                          item.da.teaserTekstSourceCode,
                                          120
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "Læs " +
                                          _vm._s(
                                            _vm.changeName(
                                              item.documentTypeAlias
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "mdi mdi-arrow-right",
                                      }),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.itemsLoaded < _vm.totalItems
              ? _c("div", { staticClass: "load-more" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          _vm.currentPage++
                        },
                      },
                    },
                    [
                      _c("p", [_vm._v("Se flere")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "mdi mdi-chevron-down" }),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }