var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { UmbracoContentService } from "@jcd-web/basic-umbraco";
import ContentRendererItem from "components/ContentRendererItem.vue";
import "components/filters/text";
import * as moment from 'moment';
class Row {
    constructor(columns) {
        this.columns = columns;
    }
}
class Column {
    constructor(items) {
        this.items = [];
        this.items = items;
    }
}
let ContentRenderer = class ContentRenderer extends Vue {
    constructor() {
        super(...arguments);
        // vars
        this.articlesPrPage = 7;
        this.currentPage = 1;
        this.defaultImage = "/app/dist/assets/images/default.jpg";
        this.contentSource = [];
        this.totalItems = 0;
        this.itemsLoaded = 0;
        this.selectedFilter = null;
    }
    get rows() {
        let source = this.filteredContent;
        let rows = [];
        if (source) {
            let itemCount = 0;
            let itemsPerRow = 7;
            let currentRow = 0;
            let localIndex = 0;
            for (let i = 0; source.length > i; i++) {
                if (!rows[currentRow]) {
                    rows.push(new Row([new Column([]), new Column([])]));
                }
                let _row = rows[currentRow];
                let _column = localIndex == 0 || (localIndex % 2 == 0 && localIndex % 6 !== 0)
                    ? _row.columns[0]
                    : _row.columns[1];
                source[i].index = localIndex;
                _column.items.push(source[i]);
                localIndex++;
                itemCount++;
                if (itemCount === itemsPerRow * (currentRow + 1)) {
                    currentRow++;
                    localIndex = 0;
                }
            }
        }
        return rows;
    }
    //If a umbraco field sortDate (datetime) is unset, it returns the lowest possible value
    //Check to see if item has a sortDate, if not use createDate to sort
    getSortDate(item) {
        const minDate = moment.utc("0001-01-01");
        if (moment.utc(item.da.sortDate).isSameOrAfter(minDate)) {
            return item.da.sortDate;
        }
        else {
            return item.createDate;
        }
    }
    get filteredContent() {
        let result;
        try {
            if (this.contentSource && this.contentSource.length > 0) {
                result = this.contentSource.filter(x => x.da.filterCategory == this.selectedFilter || !this.selectedFilter
                    ? true
                    : false).sort((a, b) => new Date(this.getSortDate(b)).getTime() -
                    new Date(this.getSortDate(a)).getTime());
                result = result.slice(0, this.currentPage * this.articlesPrPage);
                this.itemsLoaded = this.articlesPrPage * this.currentPage;
                this.fetchFilters;
            }
        }
        catch (ex) {
            console.log(ex);
        }
        return result;
    }
    get fetchFilters() {
        if (!!this.contentSource) {
            const filterCategories = this.contentSource.filter(x => (x.da.filterCategory ? true : false));
            let result = [...new Set(filterCategories.map(x => x.da.filterCategory))];
            return result;
        }
    }
    toggleFilter(filter) {
        this.currentPage = 1;
        this.selectedFilter = filter;
    }
    mounted() {
        const fetchProperties = [
            "filterCategory",
            "titel",
            "teaserTekstSourceCode",
            "documentTypeAlias",
            "uddannelse",
            "bognavn",
            "forfatter",
            "dato",
            "bookSkimForfatter",
            "billede",
            "baggrundsfarve",
            "tekstfarve",
            "dato",
            "createDate",
            "bookSkimForfatter",
            "teaserTekst",
            "kategori",
            "person",
            "podcastNavn",
            "episodeNummer",
            "title",
            "teaser",
            "portrait",
            "stilling",
            "virksomhed",
            "overskrift",
            "updateDate",
            "sortDate"
        ];
        this.sourceIds.forEach(source => {
            UmbracoContentService.getChildren(source, "", fetchProperties, 2, 0, 1000)
                .then(res => {
                let items = res;
                this.contentSource.push.apply(this.contentSource, items.results);
                this.totalItems += items.results.length;
                // console.log(this.contentSource);
            })
                .catch(err => {
                console.error("error", err);
            });
        });
    }
};
ContentRenderer = __decorate([
    Component({
        props: {
            sourceIds: Array
        },
        components: {
            ContentRendererItem
        }
    })
], ContentRenderer);
export default ContentRenderer;
