var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section podcasts-section" }, [
    _c("div", { attrs: { container: "" } }, [
      _c("div", { attrs: { grid: "wrap" } }, [
        _c("div", { staticClass: "podcasts-list", attrs: { column: "" } }, [
          _c("div", { attrs: { grid: "wrap" } }, [
            _c(
              "div",
              { staticClass: "filter-container", attrs: { column: "l-12" } },
              [
                _c(
                  "button",
                  {
                    class: { filter: true, active: !_vm.selectedFilter },
                    on: {
                      click: function ($event) {
                        _vm.selectedFilter = null
                      },
                    },
                  },
                  [_vm._v("Alle")]
                ),
                _vm._v(" "),
                _vm._l(_vm.fetchFilters, function (podcast, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      class: {
                        filter: true,
                        active: _vm.selectedFilter == podcast,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFilter(podcast)
                        },
                      },
                    },
                    [_vm._v(_vm._s(podcast))]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.itemsLoaded < _vm.totalItems
            ? _c("div", { staticClass: "load-more" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        _vm.currentPage++
                      },
                    },
                  },
                  [
                    _c("p", [_vm._v("Se flere")]),
                    _vm._v(" "),
                    _c("i", { staticClass: "mdi mdi-chevron-down" }),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }