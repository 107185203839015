var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { UmbracoContentService } from "@jcd-web/basic-umbraco";
import * as moment from "moment";
let Podcasts = class Podcasts extends Vue {
    constructor() {
        super(...arguments);
        // VARS
        this.selectedFilter = null;
        this.podcastsPrPage = 12;
        this.currentPage = 1;
        this.defaultImage = "/app/dist/assets/images/default.jpg";
        this.itemsLoaded = 0;
        this.totalItems = 0;
        this.podcastsSource = [];
    }
    formatDate(dt) {
        let newDate = moment(dt).format("DD.MM.YY");
        return newDate;
    }
    get filteredPodcasts() {
        let result;
        result = this.podcastsSource.filter(x => moment(x.da.dato).format("YYYY") == this.selectedFilter || !this.selectedFilter
            ? true
            : false);
        this.totalItems = result.length; //total number of items with the applied filter
        result = result.slice(0, this.currentPage * this.podcastsPrPage);
        this.itemsLoaded = result.length; //items loaded into view with applied filter
        return result;
    }
    get fetchFilters() {
        const podcastsWithDate = this.podcastsSource.filter(x => (x.da.dato ? true : false));
        let result = [
            ...new Set(podcastsWithDate.map(item => moment(item.da.dato).format("YYYY")))
        ];
        // console.log("result", result)
        return result;
    }
    mounted() {
        //console.log(this.source); //page id from razor
        const fetchProperties = [
            "titel",
            "person",
            "teaserTekst",
            "dato",
            "billede"
        ];
        UmbracoContentService.getChildren(this.source, "podcast", fetchProperties)
            .then(res => {
            let test = res;
            this.podcastsSource = test.results;
            //console.log("response", res); //all podcast children of the page id
        })
            .catch(err => { });
    }
    toggleFilter(filter) {
        this.currentPage = 1;
        this.selectedFilter = filter;
    }
};
Podcasts = __decorate([
    Component({
        props: {
            source: Number
        }
    })
], Podcasts);
export default Podcasts;
